import React, { ReactElement, Fragment } from 'react';
import {
  makeStyles,
  Typography,
  Avatar,
  createStyles,
  Theme,
} from '@material-ui/core';
import { FaQuoteLeft } from 'react-icons/fa';

interface Props {
  imgUrl: string;
  text: string;
  name?: string;
  isQuote: boolean;
}

function TestimonialBlock(prop: Props): ReactElement {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      grid: {
        display: 'grid',
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: '1fr 2fr',
        },
        [theme.breakpoints.down('lg')]: {
          gridTemplateColumns: '1fr',
        },
        gridGap: 6,
        gridAutoRows: 'minmax(200px, auto)',
        gridAutoColumns: 'minmax(200px, auto)',
      },
      avatar: {
        width: theme.spacing(30),
        height: theme.spacing(30),
      },
      quote: {
        fontSize: 30,
        color: '#1ebba3',
      },
      text: {
        alignItems: 'center',
        alignContent: 'center',
      },
      testimonialHr: {
        borderTop: '3px solid #1ebba3',
      },
    })
  );
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.grid}>
        <Avatar className={classes.avatar} src={prop.imgUrl} />
        <div>
          {prop.isQuote == true ? (
            <FaQuoteLeft className={classes.quote} />
          ) : null}
          <blockquote>
            <div className={classes.text}>
              <Typography> {prop.text}</Typography>
            </div>
          </blockquote>

          {prop.name && (
            <div>
              <hr className={classes.testimonialHr} />
              <cite>&#8212; {prop.name}</cite>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default TestimonialBlock;
