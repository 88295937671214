import axios from 'axios';
import { Sku } from '../../models/inventoryItem';

export async function getAllSkus() {
  const res = await axios.get(`${process.env.STRIPE_SERVICE_URL}/skus`);
  const data = res.data.data;
  const skus: Sku[] = [];
  data.forEach((it: any) => {
    const obj: Sku = {
      id: it.id,
      productId: it.product,
      currency: it.currency,
      image: it.image,
      price: it.price,
    };
    skus.push(obj);
  });
  return skus;
}
