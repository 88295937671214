import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { FiTrendingUp } from 'react-icons/fi';
import { TiGroup } from 'react-icons/ti';
import { Route } from 'react-router';
import FixedBackground from '../components/FixedBackground';
import ImageBlock from '../components/ImageBlock';
import Jumbotron from '../components/Jumbotron';
import StyledButton from '../components/StyledButton';
import TestimonialBlock from '../components/TestimonialBlock';
import TitleBanner from '../components/TitleBanner';

const andy_headshot = require('../../assets/andy_headshot.jpeg');
const tia_headshot = require('../../assets/tia_headshot.jpeg');
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    titleBanner: {
      width: '100%',
    },
    imageGrid: {
      display: 'grid',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '2rem',
      gridAutoRows: 'minmax(200px, auto)',
      gridAutoColumns: 'minmax(200px, auto)',
    },
    flexBox: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    offers: {
      width: '100%',
    },
    offset: {
      padding: '55px 10px',
    },
    offersGrid: {
      display: 'grid',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '2rem',
      gridAutoRows: 'minmax(200px, auto)',
      gridAutoColumns: 'minmax(200px, auto)',
    },
    offersHr: {
      margin: '15px 45% 55px 45%',
    },
    learnText: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '72px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '42px',
      },
    },
    square: {
      borderStyle: 'solid',
      borderColor: '#1e85bb',
      borderWidth: 2,
      margin: '0 5%',
      padding: '2%',
    },
    textBlock: {
      textAlign: 'center',
      margin: 15,
    },
    subHr: {
      margin: '15px 45%',
    },
    offersIcon: {
      fontSize: 65,
      color: '#1ebba3',
      margin: 25,
    },
    testimonialGrid: {
      display: 'grid',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '5rem',
    },
    testimonial: {
      margin: 30,
    },
    testimonialTitle: {
      margin: 32,
    },
  })
);

const backgroundImg = `https://images.unsplash.com/photo-1508804185872-d7badad00f7d?auto=format&fit=crop&w=1440&q=80`;
const fixedImg = 'https://source.unsplash.com/pMW4jzELQCw/1600x900';

function mainBtnAction(): void {
  window.open(
    `https://www.youtube.com/c/tiaandandy?sub_confirmation=1`,
    '_blank'
  );
}

const text = `Are you thinking about starting your own channel but aren’t sure what to get? We’ve got you covered! Check out our gear reviews!`;
const gear = `https://images.unsplash.com/photo-1431068799455-80bae0caf685?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9`;

function Home(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TitleBanner
        className={classes.titleBanner}
        title={'Tia & Andy'}
        tagLine={'Vacation Days'}
        backgroundUrl={backgroundImg}
        buttonText={'subscribe on youtube'}
        onClick={mainBtnAction}
      />

      {/* our gear */}
      <Jumbotron>
        <div className={classes.imageGrid}>
          <ImageBlock imgUrl={gear} title="Our Gear" paragraph={text}>
            <Route
              render={({ history }) => (
                <StyledButton
                  onClick={() => {
                    history.push('/gear');
                  }}>
                  <Typography> Gear Reviews </Typography>
                </StyledButton>
              )}
            />
          </ImageBlock>
        </div>
      </Jumbotron>
      {/* things we offer */}
      <FixedBackground backgroundUrl={fixedImg} className={classes.offers}>
        <div className={classes.offset}>
          <div className={classes.textBlock}>
            <Typography variant={'h2'} className={classes.learnText}>
              {' '}
              Learn from us
            </Typography>
            <hr className={classes.offersHr} />
            <div className={classes.square}>
              <Typography variant={'h4'} gutterBottom={true}>
                Want to do what we do but don't know where to start? We have
                your answer!
              </Typography>
              <Typography variant={'body1'}>
                Honestly, it's REALLY hard! Running a YouTube channel is a full
                time job. Creating content, finding and engaging with your
                audience, managing your social media accounts... the list goes
                on! All of this requires time committment, and costs money to
                produce. After a year of exploration, trial and error, wins and
                loses, we're finally finding a formulae that works and is
                repeatable! So if you're thinking about starting your own
                channel and get into the world of social media, hit us up! We've
                condensed our finding into books, tutorials, tips & tricks, plus
                so much more!
              </Typography>
            </div>
          </div>
          <div className={classes.offersGrid}>
            <div className={classes.textBlock}>
              <Typography variant={'h3'}>Travel for Cheap</Typography>
              <hr className={classes.subHr} />
              <FaPaperPlane className={classes.offersIcon} />
              <Typography variant={'body1'}>
                We are your travel experts! Find out how to maximize your buck
                and time in our exclusive ebook. In the book, you will get all
                of our best tips & tricks for travelling on the cheap. The world
                is there for you to explore! Let us show you how to do it
                without breaking the bank.
              </Typography>
            </div>
            <div className={classes.textBlock}>
              <Typography variant={'h3'}>Build Your Following</Typography>
              <hr className={classes.subHr} />
              <TiGroup className={classes.offersIcon} />
              <Typography variant={'body1'}>
                It's not all about how big your following is! This is a common
                mistake that new influencers make. Your following is only useful
                if they are focused. Plus, there's so many social media outlets
                out there. Which one is best for you? We have experimented with
                all the major social media outlets and have sorted out exactly
                what you should focus on for your business. Skip the trial and
                error and let us give you a plan of action to grow your
                audience.
              </Typography>
            </div>
            <div className={classes.textBlock}>
              <Typography variant={'h3'}>Go To Market</Typography>
              <hr className={classes.subHr} />
              <FiTrendingUp className={classes.offersIcon} />
              <Typography variant={'body1'}>
                People often think of going to market as a final step before
                monetization. This is not the case any more! In the digital
                world, the key is to iterate. Using our unique skillset of Agile
                and Project Management, we use an modern approach with going to
                market. We are all about going to market quickly and going
                often. We use technology to reduce the space between you and
                your customer to ensure that you deliver exactly what they want!
              </Typography>
            </div>
          </div>
        </div>
      </FixedBackground>
      {/*  about us */}
      <Jumbotron>
        <Typography className={classes.testimonialTitle} variant={'h3'}>
          About Us
        </Typography>
        <div className={classes.testimonial}>
          <div className={classes.testimonialGrid}>
            <TestimonialBlock
              imgUrl={andy_headshot}
              text={`I am a Software Developer.  It's my craft.  My Passion.  Travel is the only other thing I am as passionate about.  My day job keeps me indoors but the world is out there to experience.  I take every opportunity to get our there an see a bit more of the world.`}
              name={'Andy'}
              isQuote={false}></TestimonialBlock>
            <TestimonialBlock
              imgUrl={tia_headshot}
              text={`I take travel seriously.  It's my way of life. Some girls spend money on purses, I spend money on vacations.  During the day, I'm a Program Manager.  I speak fluent Developer and my job is to translate people's needs to tech talk. By night, I use my Project Management skills to find our next vacation.`}
              name={'Tia'}
              isQuote={false}></TestimonialBlock>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
}
export default Home;
