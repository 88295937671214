import React, { ReactElement, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import SuccessSVG from '../../assets/SuccessSVG';

let image = `https://source.unsplash.com/I9j8Rk-JYFM/3440x1440`;

if (screen.width < screen.height) {
  image = `https://source.unsplash.com/m82uh_vamhg/1125x2436`;
}

export function SuccessPage(): ReactElement {
  const useStyle = makeStyles({
    root: {
      height: '100vh',
      width: '100vw',
      opacity: 0.65,
      objectFit: 'cover',
      backgroundImage: `url('${image}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    error: {
      width: '75vw',
      height: 'auto',
    },
  });

  useEffect(() => {
    window.localStorage.setItem('itemsInCart', JSON.stringify([]));
  }, []);

  const classes = useStyle();
  return (
    <div className={classes.root}>
      <SuccessSVG className={classes.error} />
    </div>
  );
}

export default SuccessPage;
