import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { AiFillHome } from 'react-icons/ai';
import {
  FaInstagram,
  FaPatreon,
  FaTwitterSquare,
  FaYoutube,
} from 'react-icons/fa';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { InventoryItem } from '../../models/inventoryItem';
import Appbar from '../components/Appbar';
import Cart from '../components/Cart';
import Footer from '../components/Footer';
import createCtx from '../utils/ContextUtil';
import ErrorPage from './ErrorPage';
import Gear from './Gear';
import Home from './Home';
import Store from './Store';
import SuccessPage from './SuccessPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    cart: {
      position: 'fixed',
      top: '70px',
      right: '10px',
      zIndex: 2,
    },
    cartOpen: {
      width: 700,
      height: 900,
      transition: theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    cartClose: {
      width: 0,
      height: 0,
      transition: theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
);

const socials = [
  {
    icon: FaYoutube,
    path: 'https://youtube.com/c/tiaandandy',
    fontSize: 55,
  },
  {
    icon: FaTwitterSquare,
    path: 'https://twitter.com/tavacationdays',
    fontSize: 55,
  },
  {
    icon: FaInstagram,
    path: 'https://www.instagram.com/tiaandyvacationdays/',
    fontSize: 55,
  },
  {
    icon: FaPatreon,
    path: 'https://www.patreon.com/tiaandandy',
    fontSize: 55,
  },
];
export const [ShoppingCart, CartProvider] = createCtx<InventoryItem[]>([]);
export default function Front() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useState(screen.width <= 480);
  const toggleCart = () => {
    if (isMobile) {
      window.location.href = '/Cart';
    } else {
      setIsOpen(!isOpen);
    }
  };
  const goToStore = () => {
    window.location.href = '/store';
  };
  return (
    <div className={classes.root}>
      <CartProvider>
        <BrowserRouter>
          <Appbar
            menuIcon={{ icon: AiFillHome, fontSize: 25 }}
            pageName="VACATION DAYS"
            menuItem={[
              {
                text: 'store',
                onClick: goToStore,
                showBadge: false,
              },
              {
                text: 'cart',
                onClick: toggleCart,
                showBadge: true,
              },
            ]}
          />

          {!isMobile && (
            <Paper
              className={clsx(classes.cart, {
                [classes.cartOpen]: isOpen,
                [classes.cartClose]: !isOpen,
              })}
              elevation={5}>
              <Cart />
            </Paper>
          )}

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/store" component={Store} />
            {isMobile && <Route path="/cart" component={Cart} />}
            <Route path="/gear" component={Gear} />
            <Route path="/success" component={SuccessPage} />
            <Route path="*" component={ErrorPage} />
          </Switch>
        </BrowserRouter>
      </CartProvider>

      <Footer
        tagLine={`Your Modern Age Vagabonds`}
        email={`tavacationdays@gmail.com`}
        copyright={`Vacation Days`}
        icon={socials}
      />
    </div>
  );
}
