import { MuiThemeProvider } from '@material-ui/core';
import React, { Fragment } from 'react';
import Front from '../ui/views/Front';
import SEO from './components/SEO';
import { THEME } from './style/Theme';

const App = () => {
  return (
    <Fragment>
      <SEO
        title="Tia & Andy"
        caption="Vacation Days"
        description="Travelling while working full time! Sharing our adventures on YouTube!"
        siteUrl="https://tiaandandy.com"
        lang="en_US"
      />
      <MuiThemeProvider theme={THEME}>
        <Front />
      </MuiThemeProvider>
    </Fragment>
  );
};

export default App;
