import { createStyles, makeStyles, Typography, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';
import StaticBackground from './StaticBackground';
import StyledButton from './StyledButton';

interface BannerProp {
  className?: string;
  title: string;
  tagLine: string | '';
  height?: number;
  buttonText?: string;
  backgroundUrl: string;
  onClick?: () => void;
}

function TitleBanner(prop: BannerProp) {
  const height = prop.height ? `${prop.height}vh` : '100vh';
  const top = prop.height ? (38 * prop.height!!) / 100 + '%' : '38%';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        height: height,
      },
      caption: {
        textAlign: 'center',
        width: '100%',
        maxWidth: '100%',
        top: top,
        zIndex: 1,
        color: '#ffffff',
        textTransform: 'uppercase',
        position: 'absolute',
      },
      img: {
        objectFit: 'cover',
        width: '100%',
        height: height,
      },
      titleText: {
        [theme.breakpoints.up('lg')]: {
          fontSize: '61px',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: '42px',
        },
        letterSpacing: '4.8px',
        textShadow: '1.6px 1.6px 1.6px #000000',
        paddingBottom: '16px',
      },
      tagLine: {
        [theme.breakpoints.up('lg')]: {
          fontSize: '42px',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: '22px',
        },
        textShadow: '1.6px 1.6px 1.6px #000000',
        paddingBottom: '25.6px',
      },
      buttonStyle: {
        textTransform: 'uppercase',
      },
      btnTypography: {
        color: '#ffffff',
        fontWeight: 400,
      },
    })
  );

  const classes = useStyles();

  return (
    <Fragment>
      <div className={prop.className}>
        <StaticBackground height={prop.height}>
          <img className={classes.img} src={prop.backgroundUrl} />;
        </StaticBackground>
        <div className={classes.caption}>
          <Typography className={classes.tagLine} variant={'h3'}>
            {' '}
            {prop.tagLine}
          </Typography>
          <Typography className={classes.titleText} variant={'h1'}>
            {' '}
            {prop.title}
          </Typography>
          {prop.buttonText && (
            <StyledButton
              className={classes.buttonStyle}
              variant="contained"
              onClick={prop.onClick}>
              <Typography variant={'h6'} className={classes.btnTypography}>
                {prop.buttonText}
              </Typography>
            </StyledButton>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default TitleBanner;
