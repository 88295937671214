import { createStyles, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface BackgroundProp {
  className?: string;
  backgroundUrl: string;
  style?: any;
  children?: ReactNode;
}

function FixedBackground(prop: BackgroundProp) {
  const useStyle = makeStyles(() =>
    createStyles({
      fixedBackground: {
        zIndex: -1,
        width: '100%',
      },
      backgroundInner: {
        backgroundImage: `url('${prop.backgroundUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
      },
      dark: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: '#ffffff',
        padding: '122 32',
      },
    })
  );

  const classes = useStyle();

  return (
    <div className={prop.className} style={prop.style}>
      <div className={classes.backgroundInner}>
        <div className={classes.fixedBackground}>
          <div className={classes.dark}>{prop.children}</div>
        </div>
      </div>
    </div>
  );
}

export default FixedBackground;
