import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import { MdAddShoppingCart } from 'react-icons/md';
import { InventoryItem } from '../../models/inventoryItem';
import { ShoppingCart } from '../views/Front';
import PaperModal from './PaperModal';

interface ItemProp {
  Item: InventoryItem;
}

export default function StoreItemCard(prop: ItemProp) {
  const item = prop.Item;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        width: '100%',
        height: '100%',
      },
      card: {
        height: '100%',
        widht: '100%',
      },
      modal: {
        display: 'flex',
        flexDirection: 'column',
      },
      img: {
        [theme.breakpoints.up('md')]: {
          minWidth: 450,
          minHeight: 400,
        },
        [theme.breakpoints.down('md')]: {
          minWidth: 250,
          minHeight: 200,
        },
        backgroundImage: `url('${item.image}')`,
        backgroundColor: '#ffffff',
        objectFit: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        marginBottom: '1em',
      },
      productDesc: {
        margin: 5,
      },
      descriptionField: {
        height: 20,
        padding: 5,
      },
      actionGrid: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'center',
      },
      shareIcon: {
        color: '#24a0ed',
        flex: '1',
      },
      faved: {
        color: '#ff0000',
      },
      unfaved: {
        color: 'rbga(0,0,0,0.5)',
      },
      cartIcon: {
        '&:hover': {
          backgroundColor: '#24a0ed',
          transform: 'scale(1.2)',
          boxShadow:
            '0px  25px 40px -5px rgba(#000, 0.3), inset 0px   0px  0px  0px rgba($red, 0), inset 0px -27px  0px  0px $red2',
        },
      },
      price: {
        padding: 5,
        alignSelf: 'center',
      },
    })
  );

  const classes = useStyles();
  const [like, setLike] = React.useState(false);
  useEffect(() => {
    const storage = window.localStorage.getItem('favItems');
    const favs: InventoryItem[] = storage ? JSON.parse(storage) : [];

    const found = favs.find((it: InventoryItem) => it.id === item.id);
    if (found) {
      setLike(true);
    } else {
      setLike(false);
    }
  });
  const toggleLike = (item: InventoryItem) => {
    const storage = window.localStorage.getItem('favItems');
    const favs: InventoryItem[] = storage ? JSON.parse(storage) : [];

    if (like) {
      const reduced = favs.filter((it: InventoryItem) => {
        return it.id !== item.id;
      });

      window.localStorage.setItem('favItems', JSON.stringify(reduced));
    } else {
      favs.push(item);
      window.localStorage.setItem('favItems', JSON.stringify(favs));
    }
    setLike(!like);
  };
  useEffect(() => {
    const storage = window.localStorage.getItem('favItems');
    const favs: InventoryItem[] = storage ? JSON.parse(storage) : [];
    const found = favs.find((it: InventoryItem) => it.id === item.id);

    if (found) {
      setLike(!like);
    }
  }, []);

  const { state: cart, update: updateCart } = useContext(ShoppingCart);
  const addToCart = (item: InventoryItem) => {
    const storage = window.localStorage.getItem('itemsInCart');

    if (storage) {
      const currentItems: InventoryItem[] = JSON.parse(storage);
      currentItems.push(item);
      window.localStorage.setItem('itemsInCart', JSON.stringify(currentItems));
    }

    updateCart([...cart, item]);
  };

  let truncatedDesc = '';

  if (item.description != undefined) {
    if (item.description.length > 111) {
      truncatedDesc = `${item.description.substring(0, 111)}...`;
    } else {
      truncatedDesc = item.description;
    }
  }

  const price = item.price / 100;
  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Card className={classes.card}>
          <CardHeader title={item.name}></CardHeader>
          <CardContent>
            <PaperModal item={item} className={classes.modal}>
              <div className={classes.img} />
            </PaperModal>
            <Typography className={classes.productDesc} variant={'h6'}>
              Product Description:
            </Typography>
            <Typography className={classes.descriptionField}>
              {' '}
              {truncatedDesc}{' '}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Grid className={classes.actionGrid}>
              <IconButton aria-label="fav!" onClick={() => toggleLike(item)}>
                <FaHeart
                  className={clsx({
                    [classes.faved]: like,
                    [classes.unfaved]: !like,
                  })}
                />
              </IconButton>
              {/* <IconButton aria-label="share!">
                <MdShare className={classes.shareIcon} />
              </IconButton> */}
              <IconButton
                className={classes.cartIcon}
                aria-label="cart"
                onClick={() => addToCart(item)}>
                <MdAddShoppingCart />
              </IconButton>
              <Typography className={classes.price} variant={'h6'}>
                ${price}
              </Typography>
            </Grid>
          </CardActions>
        </Card>
      </Paper>
    </Fragment>
  );
}
