import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import env from 'dotenv';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { MdFilterList } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { getAllProducts } from '../../api/stripe/products';
import { getAllSkus } from '../../api/stripe/skus';
import { InventoryItem, Product, Sku } from '../../models/inventoryItem';
import StoreItemCard from '../components/StoreItemCard';

if (process.env.NODE_ENV === 'dev') {
  env.config({ path: '.env' });
}

interface Props {
  children?: ReactNode;
}
const openimg = 'https://source.unsplash.com/c9FQyqIECds/1600x900';

function Store(prop: Props) {
  const { children, ...props } = prop;
  function buildInventoryItem(sku: Sku, product: Product): InventoryItem {
    const inventoryItem = {
      quantity: 0,
      currency: sku.currency,
      image: sku.image,
      id: sku.id,
      price: sku.price,
      productId: sku.productId,
      active: product.active,
      name: product.name,
      description: product.description,
      caption: product.caption,
      metadata: {
        productType: product.metadata.productType,
      },
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,
    };

    return inventoryItem;
  }

  const [storeInventory, updateInventory] = useState<InventoryItem[]>([]);

  useEffect(() => {
    const storage = window.localStorage.getItem('favItems');
    if (!storage) {
      window.localStorage.setItem('favItems', JSON.stringify([]));
    }
  }, []);

  useEffect(() => {
    async function getInventory() {
      const products = await getAllProducts();
      const skus = await getAllSkus();
      const items: InventoryItem[] = [];
      products.forEach((it: Product) => {
        const sku = skus.find(sku => it.id === sku.productId);
        if (sku) {
          const item = buildInventoryItem(sku, it);
          items.push(item);
        }
      });

      updateInventory([...items]);
    }
    getInventory();
  }, []);

  const [categories, updateCategories] = useState<string[]>([]);

  useEffect(() => {
    const types: string[] = ['all'];
    storeInventory.forEach((item: InventoryItem) => {
      const type = item.metadata.productType;
      if (!types.includes(type)) {
        types.push(type);
      }
    });
    updateCategories([...types]);
  }, [storeInventory]);

  const [displayedItems, updateDisplayedItems] = useState<InventoryItem[]>([]);

  useEffect(() => {
    const displayed = Array.from(storeInventory);
    updateDisplayedItems([...displayed]);
  }, [storeInventory]);

  const filter = (type: string) => {
    if (type === 'all') {
      updateDisplayedItems([...storeInventory]);
      return;
    }

    const copy = Array.from(storeInventory);
    const filter = copy.filter(it => {
      return it.metadata.productType === type;
    });
    updateDisplayedItems([...filter]);
  };

  const getFavs = () => {
    const storage = window.localStorage.getItem('favItems');

    const fav: InventoryItem[] = storage ? JSON.parse(storage) : [];

    if (Object.keys(fav).length > 0) {
      updateDisplayedItems([...fav]);
    } else {
      updateDisplayedItems([]);
    }
  };

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minheight: '100vh',
      },
      storeBanner: {
        top: 65,
        objectFit: 'cover',
        width: '100%',
        height: '25vh',
      },
      storeFront: {
        height: '100%',
        padding: '5% 10%',
      },
      filterFlexBox: {
        [theme.breakpoints.up('md')]: {
          width: '100vw',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          overflowX: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          width: '100vw',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          overflowX: 'auto',
        },
      },
      navBtn: {
        border: 'transparent',
        background: 'transparent',
        margin: '1rem',
        outline: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      hr: {
        borderTop: '#97999c',
      },
      inventoryGrid: {
        display: 'grid',
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.between('md', 'lg')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
        },

        gridGap: '5em',
        gridAutoRows: 'minmax(200px, auto)',
        gridAutoColumns: 'minmax(200px, auto)',
      },
      filter: {
        display: 'flex',
      },
      filterText: {
        fontSize: '12px',
      },
    })
  );

  const [isMobile] = useState(screen.width <= 480);
  const [isOpen, updateIsOpen] = useState(false);

  const toggleDrawer = () => {
    updateIsOpen(!isOpen);
  };

  const classes = useStyle();

  return (
    <div className={classes.root} {...props}>
      <img className={classes.storeBanner} src={openimg} />
      {isMobile ? (
        <div className={classes.filter}>
          <IconButton onClick={toggleDrawer}>
            <MdFilterList />
          </IconButton>
          {isOpen &&
            categories &&
            categories.map(function(it: string, i: number) {
              return (
                <button
                  className={classes.navBtn}
                  onClick={() => {
                    filter(it);
                    toggleDrawer();
                  }}
                  key={i}>
                  <Typography className={classes.filterText} variant={'h4'}>
                    {it}
                  </Typography>
                </button>
              );
            })}
        </div>
      ) : (
        <>
          <div className={classes.filterFlexBox}>
            {categories &&
              categories.map(function(it: string, i: number) {
                return (
                  <button
                    className={classes.navBtn}
                    onClick={() => filter(it)}
                    key={i}>
                    <Typography variant={'h4'}> {it} </Typography>
                  </button>
                );
              })}
            <button className={classes.navBtn} onClick={() => getFavs()}>
              <Typography variant={'h4'}> my favs! </Typography>
            </button>
          </div>
          <hr className={classes.hr} />
        </>
      )}

      <div className={classes.storeFront}>
        <div className={classes.inventoryGrid}>
          {displayedItems &&
            displayedItems.map(function(it, i) {
              const cItem = { quantity: 0, ...it };
              return <StoreItemCard key={i} Item={cItem} />;
            })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Store);
