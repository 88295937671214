import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { InventoryItem } from '../../models/inventoryItem';
import { ShoppingCart } from '../views/Front';

interface Prop {
  className?: string;
  children?: ReactNode;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.up('md')]: {
        margin: '0 15%',
      },
      [theme.breakpoints.down('md')]: {
        height: '100vh',
        margin: '0 5%',
        position: 'relative',
      },
    },
    cart: {
      [theme.breakpoints.up('md')]: {
        height: 700,
        overflowY: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        top: 65,
        position: 'absolute',
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gridGap: '1rem',
      margin: '3%',
    },
    img: {
      [theme.breakpoints.up('md')]: {
        height: 150,
        width: 225,
      },
      [theme.breakpoints.down('md')]: {
        height: 90,
        width: 115,
      },
      objectFit: 'contain',
    },
    itemName: {
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontSize: '61px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '32px',
      },
    },
    quantitySelector: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: 30,
    },
    quantityText: {
      alignSelf: 'center',
    },
    quantityBtn: {
      background: '#7F7EFF',
      borderRadius: 25,
      '&:hover': {
        background: '#7F7FBF',
      },
    },
    checkoutBtn: {
      border: 'solid black',
      padding: '1%',
      margin: '1%',
    },
  })
);

function Cart(prop: Prop) {
  const { className, children, ...props } = prop;
  const classes = useStyle();
  const { state: cart, update: updateCart } = useContext(ShoppingCart);
  const [reducedItems, updateReducedItems] = useState<InventoryItem[]>([]);
  const url = `${window.location.protocol}//${window.location.host}`;
  useEffect(() => {
    const storage = window.localStorage.getItem('itemsInCart');

    const currentItems = storage ? JSON.parse(storage) : {};

    if (Object.keys(currentItems).length > 0) {
      updateCart([...currentItems]);
    } else {
      window.localStorage.setItem('itemsInCart', JSON.stringify([]));
    }
    return () => {
      const stringified = JSON.stringify(cart);
      window.localStorage.setItem('itemsInCart', stringified);
    };
  }, []);

  useEffect(() => {
    let temp = Array.from(cart);
    const updated = temp.reduce<InventoryItem[]>((acc, item) => {
      const found = acc.find(_item => item.id === _item.id);
      if (found) {
        found.quantity += 1;
      } else {
        item.quantity = 1;
        acc.push({ ...item });
      }
      return acc;
    }, []);

    updateReducedItems(updated);
  }, [cart]);

  function addItem(item: InventoryItem): void {
    const storage = window.localStorage.getItem('itemsInCart');
    const currentItems: InventoryItem[] = storage ? JSON.parse(storage) : {};
    currentItems.push(item);

    window.localStorage.setItem('itemsInCart', JSON.stringify(currentItems));
    updateCart([...currentItems]);
  }

  function removeItem(item: InventoryItem): void {
    const storage = window.localStorage.getItem('itemsInCart');
    const currentItems: InventoryItem[] = storage ? JSON.parse(storage) : {};
    const index = currentItems.findIndex(element => element.id === item.id);
    if (index !== -1) {
      currentItems.splice(index, 1);
      window.localStorage.setItem('itemsInCart', JSON.stringify(currentItems));
      updateCart([...currentItems]);
    }
  }

  function checkout() {
    if (process.env.STRIPE_API_KEY) {
      Stripe(process.env.STRIPE_API_KEY).redirectToCheckout({
        items: reducedItems.map<{ sku: string; quantity: number }>(item => ({
          sku: item.id,
          quantity: item.quantity,
        })),
        successUrl: `${url}/success`,
        cancelUrl: `${url}/store`,
      });
    } else {
      window.location.href = '/error';
    }
  }

  return (
    <div className={[className, classes.wrapper].join(' ')} {...props}>
      <div flex-grow={1} className={classes.cart}>
        <Grid item xs={12}>
          <Typography variant={'h2'} className={classes.title}>
            Items
          </Typography>
        </Grid>
        <div>
          {reducedItems &&
            reducedItems.map(function(it, i) {
              return (
                <div key={i} className={classes.grid} id="ladeda">
                  <img className={classes.img} src={it.image} />
                  <div>
                    <Typography className={classes.itemName} variant={'h6'}>
                      {it.name}
                    </Typography>
                    <div className={classes.quantitySelector}>
                      <Button
                        className={classes.quantityBtn}
                        size="small"
                        onClick={() => removeItem(it)}>
                        <FaMinus />
                      </Button>
                      <Typography
                        className={classes.quantityText}
                        variant={'h5'}
                        align={'center'}>
                        {it.quantity}
                      </Typography>
                      <Button
                        className={classes.quantityBtn}
                        size="small"
                        onClick={() => addItem(it)}>
                        <FaPlus />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Button className={classes.checkoutBtn} onClick={checkout}>
          <Typography variant={'body1'}>Check Out</Typography>
        </Button>
      </div>
    </div>
  );
}

export default withRouter(Cart);
