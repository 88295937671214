import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import FixedBackground from '../components/FixedBackground';
import ImageBlock from '../components/ImageBlock';
import Jumbotron from '../components/Jumbotron';
import TitleBanner from '../components/TitleBanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: '10%',
      flexGrow: 1,
      width: '100vw',
    },
    titleBanner: {
      width: '100%',
    },
    imageGrid: {
      display: 'grid',
      margin: '2rem 0',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '2rem',
      gridAutoRows: 'minmax(200px, auto)',
      gridAutoColumns: 'minmax(200px, auto)',
    },
    flexBox: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hRule: {
      margin: '2px 25% 15px 25%',
      height: 2,
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    grid: {
      margin: 10,
    },
  })
);

const backgroundImg = 'https://source.unsplash.com/WrLY3abIUyU/1600x900';
const fixedImg = 'https://source.unsplash.com/Qhnsv_Ey2mA/450x800';
const canon =
  'https://images-na.ssl-images-amazon.com/images/I/81po4XuahmL._AC_SL1500_.jpg';
const tripod =
  'https://images-na.ssl-images-amazon.com/images/I/51ZqU9XNu8L._AC_SL1000_.jpg';
const battery =
  'https://images-na.ssl-images-amazon.com/images/I/41jEU-9rHAL._AC_.jpg';
const microphone =
  'https://images-na.ssl-images-amazon.com/images/I/81x1zTsuMIL._AC_SL1500_.jpg';
const drone =
  'https://images-na.ssl-images-amazon.com/images/I/71klC3HESdL._AC_SL1500_.jpg';
const powerbank =
  'https://images-na.ssl-images-amazon.com/images/I/51v2lP32kaL._AC_SL1000_.jpg';
const powerbar =
  'https://images-na.ssl-images-amazon.com/images/I/61Q8JWwq%2BGL._AC_SL1300_.jpg';

function Gear(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TitleBanner
        className={classes.titleBanner}
        title={'Our Gear'}
        tagLine={''}
        height={75}
        backgroundUrl={backgroundImg}
      />
      <Jumbotron>
        <Typography variant={'h3'}> We Love Gear!</Typography>
        <hr className={classes.hRule} />
        <Typography variant={'h5'}>
          We love the latest and greatest gear just as much as the next person.
          But in our experience, we know the importance of buying gear that fits
          your use and your skill level. Buying gear that is way too out of your
          skill range will only increase your learning curve and often times
          will not get you the results that you are after. We’ve specifically
          chosen our gear because it is jammed packed full of features and it’s
          at a skill level that we can handle. We’ve broken up our gear into
          categories. So take a peek! If you can’t find the information that
          you’re looking for, get in touch with us! We have too many thoughts to
          share!
        </Typography>
      </Jumbotron>
      <FixedBackground backgroundUrl={fixedImg}>
        <Jumbotron>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={canon}
              title={'Cannon g7x mkii'}
              paragraph={
                'This beginner friendly camera weighs in at 310g but it packs a punch!  It’s one of the most popular cameras on the market meaning there’s tons of community support.  Give it a try! It’s our recommended camera for anyone who is starting out.'
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={tripod}
              title={'Lammcou Gorilla Pod'}
              paragraph={
                'This is tripod and our camera are the two pieces of gear we never leave home without.  Coming in at about half the price of a Gorilla Pod, the Lammcou tripod has proven itself to be at least as awesome.  The rubbery material allows the legs to grip on to pretty much any surface and the sturdy construction gives us a complete peace of mind.'
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={battery}
              title={'Canon NL-13L Battery Pack'}
              paragraph={
                'Don’t run out of power!  Nice and simple.  We grabbed two of these along with a portable charger to ensure that our camera is always ready to get the next perfect shot.'
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={microphone}
              title={'Omnidirection Microphone'}
              paragraph={
                'This is a small addition to our recording gear but a vital one when we are outside.  This omnidirectional mic minimizes background noise very well.  It is especially effective at reducing wind noise. Definitely a great tool to have in your gear bag.'
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={drone}
              title={'DJI Spark Fly More Combo'}
              paragraph={
                'Get a head up above your competition with the DJI Spark.  This package comes with a controller and a couple of extra batteries to give you the extra flight time you need to capture that perfect aerial shot.'
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={powerbank}
              title={'RavPower Battery Bank'}
              paragraph={
                'The RavPower battery bank kept 2 phones and 3 camera batteries charged for a keep week when we were out camping. Need I say more?  '
              }></ImageBlock>
          </div>
          <div className={classes.imageGrid}>
            <ImageBlock
              imgUrl={powerbar}
              title={'TESSAN Power Bar'}
              paragraph={
                'This power bar with built in surge protector and world wide adapters is the first thing that goes into our luggage when we are traveling. It’s got enough ports to keep all our electronics charged where ever we are in the world.'
              }></ImageBlock>
          </div>
        </Jumbotron>
      </FixedBackground>
    </div>
  );
}

export default Gear;
