import axios from 'axios';
import { Product } from '../../models/inventoryItem';

export async function getAllProducts() {
  const res = await axios.get(`${process.env.STRIPE_SERVICE_URL}/products`);
  const data = res.data.data;
  const products: Product[] = [];
  data.forEach((it: any) => {
    const obj: Product = {
      id: it.id,
      active: it.active,
      name: it.name,
      description: it.description,
      caption: it.caption,
      metadata: {
        productType: it.metadata.product_type,
      },
      createdAt: it.created,
      updatedAt: it.updated,
    };
    products.push(obj);
  });
  return products;
}

export async function getProductById(productId: string) {
  const res = await axios.get(
    `${process.env.STRIPE_SERVICE_URL}/products/${productId}`
  );
  const data = res.data;
  const product: Product = {
    id: data.id,
    active: data.active,
    name: data.name,
    description: data.description,
    caption: data.caption,
    metadata: {
      productType: data.metadata.productType,
    },
    createdAt: data.created,
    updatedAt: data.updated,
  };
  return product;
}
