import React from 'react';
import Helmet from 'react-helmet';

interface SeoProp {
  title: string;
  caption: string;
  description: string;
  lang: string;
  siteUrl: string;
}

function SEO(prop: SeoProp) {
  const { title, caption, description, lang, siteUrl } = prop;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${caption}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />
  );
}

export default SEO;
