import { makeStyles, DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { ShoppingCart } from '../views/Front';
import { InventoryItem } from '../../models/inventoryItem';

interface Props {
  className?: string;
  children?: ReactNode;
  item: InventoryItem;
}

function PaperModal(prop: Props): ReactElement {
  const { className, children, item } = prop;
  const [isOpen, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const useStyles = makeStyles({
    iconBtn: {
      '&:hover': {
        background: 'transparant',
      },
    },
    title: { padding: '5%' },
    productDesc: {
      padding: 5,
    },
    content: {
      padding: '5% 10%',
      display: 'grid',
      gridRowGap: 16,
    },
    descriptionField: {
      padding: 15,
    },
    actionGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    price: {
      padding: 5,
      alignSelf: 'center',
    },
  });

  const classes = useStyles();
  const { state: cart, update: updateCart } = useContext(ShoppingCart);
  const addToCart = (item: InventoryItem) => {
    const storage = window.localStorage.getItem('itemsInCart');

    if (storage) {
      const currentItems: InventoryItem[] = JSON.parse(storage);
      currentItems.push(item);
      window.localStorage.setItem('itemsInCart', JSON.stringify(currentItems));
    }
    updateCart([...cart, item]);
  };

  const price = item.price / 100;

  return (
    <div className={className}>
      <Button className={classes.iconBtn} onClick={handleClickOpen('paper')}>
        {children}
      </Button>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        maxWidth={'lg'}
        fullWidth={true}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle className={classes.title} id="scroll-dialog-title">
          <Typography variant={'h2'}>{item.name}</Typography>
        </DialogTitle>
        <DialogContent
          className={classes.content}
          dividers={scroll === 'paper'}>
          {children}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            <Typography className={classes.productDesc} variant={'h5'}>
              Product Description:
            </Typography>
            <Typography className={classes.descriptionField} variant={'body1'}>
              {item.description}
            </Typography>
            <Typography variant={'body1'}>Shipping Information:</Typography>
            <Typography className={classes.descriptionField} variant={'body1'}>
              All Products are made to order.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionGrid}>
          <Button onClick={() => addToCart(item)} color="primary">
            Add To Cart
          </Button>
          <Typography className={classes.price} variant={'h6'}>
            ${price}
          </Typography>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PaperModal;
