import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import ErrorSVG from '../../assets/ErrorSVG';

let image = `https://source.unsplash.com/_x-PryfGq0Y/3440x1440`;

if (screen.width < screen.height) {
  image = `https://source.unsplash.com/JeL4XANbkLU/1125x2436`;
}

export default function ErrorPage(): ReactElement {
  const useStyle = makeStyles({
    root: {
      height: '100vh',
      width: '100vw',
      opacity: 0.65,
      objectFit: 'cover',
      backgroundImage: `url('${image}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    error: {
      width: '75vw',
      height: 'auto',
    },
  });

  const classes = useStyle();
  return (
    <div className={classes.root}>
      <ErrorSVG className={classes.error} />
    </div>
  );
}
