import React, { useEffect, useContext, useState, Fragment } from 'react';
import {
  makeStyles,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { Route } from 'react-router';
import { IconType } from 'react-icons/lib/cjs';
import Icon from './Icon';
import { ShoppingCart } from '../views/Front';

interface AppbarProp {
  pageName: string;
  menuItem?: {
    text: string;
    onClick?: () => void;
    showBadge: boolean;
  }[];
  menuIcon: {
    icon: IconType;
    className?: string;
    fontSize: number;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      position: 'fixed',
      textTransform: 'uppercase',
      fontSize: '15px ',
      letterSpacing: '1.6px',
    },
    menuButton: {
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
      [theme.breakpoints.up('lg')]: {
        fontSize: '22px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
    iconTypography: {
      color: '#ffffff',
    },
    badge: {
      position: 'absolute',
      top: '5px',
      right: '15px',
      padding: '5px 10px',
      borderRadius: '50%',
      backgroundColor: 'red',
      color: 'white',
      zIndex: 1,
    },
  })
);

export default function Appbar(prop: AppbarProp) {
  const { state: cart, update: updateCart } = useContext(ShoppingCart);
  const [itemCount, updateItemCount] = useState(0);

  useEffect(() => {
    const storage = window.localStorage.getItem('itemsInCart');

    let itemCount = storage ? JSON.parse(storage).length : 0;

    updateItemCount(itemCount);
  }, [cart]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Route
            render={({ history }) => (
              <IconButton
                className={classes.menuButton}
                aria-label="menu"
                onClick={() => {
                  history.push('/');
                }}>
                <Icon
                  className={classes.iconTypography}
                  icon={prop.menuIcon.icon}
                  fontSize={prop.menuIcon.fontSize}
                />
              </IconButton>
            )}
          />

          <Typography className={classes.title} variant="h6">
            {prop.pageName}
          </Typography>
          {prop.menuItem &&
            prop.menuItem.map(({ text, onClick }, i) => (
              <Fragment key={i}>
                {itemCount > 0 && (
                  <span className={classes.badge}>{itemCount}</span>
                )}
                <MenuItem onClick={onClick}>{text}</MenuItem>
              </Fragment>
            ))}
        </Toolbar>
      </AppBar>
    </div>
  );
}
