import React, { createContext, PropsWithChildren, useState } from 'react';

function createCtx<A>(defaultValue: A) {
  type UpdatedType = React.Dispatch<React.SetStateAction<typeof defaultValue>>;
  const defaultUpdate: UpdatedType = () => defaultValue;
  const ctx = createContext({
    state: defaultValue,
    update: defaultUpdate,
  });

  function Provider(props: PropsWithChildren<{}>) {
    const [state, update] = useState(defaultValue);
    return <ctx.Provider value={{ state, update }} {...props} />;
  }

  return [ctx, Provider] as [typeof ctx, typeof Provider];
}

export default createCtx;
